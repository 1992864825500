/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Markdown } from "../utils/markdown/markdown.interface";
import MarkDown from "../utils/markdown/markdown";
import Button, { ButtonProps } from "../buttons/linkButtons/Button";
import ProjectCard, { ProjectCardProps } from "../cards/project-card";
import { Link } from "gatsby";
export type HeroProps = {
  leader: Markdown | string;
  headingLine1: Markdown | string;
  headingLine2: Markdown | string;
  tagLine?: Markdown | string;
  primaryCta?: ButtonProps;
  secondaryCta?: ButtonProps;
  projects?: ProjectCardProps[];
};

export const Hero: React.FC<HeroProps> = ({
  headingLine1,
  headingLine2,
  tagLine,
  primaryCta,
  secondaryCta,
  projects,
}) => {
  const projectElements = projects
    ?.filter((p) => p.feature === true)
    .map((project, i) => {
      return (
        <div
          key={i}
          sx={{
            width: "32%",
            //height: "50%",
            //animation: `${popIn} 1s cubic-bezier(0, 0.9, 0.3, 1.2) forwards`,
            //opacity: 0,
            //animationDelay: delay,
          }}
        >
          <ProjectCard {...project} />
        </div>
      );
    });

  const heroLayout = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        bg: "supportingADark",
        height: projectElements ? ["400px", "700px", "700px"] : ["300px"],
      }}
    >
      <div
        id="box-container"
        sx={{
          variant: "layout.box.noPadding",
          display: "flex",
          alignItems: "center",
          justifyContent: ["center", "center"],
          width: "100%",
          px: [2, 0, 0],
          height: ["65%", "50%"],
          textAlign: ["center"],
        }}
      >
        <div
          id="hero"
          sx={{ ...heroLayout, fontFamily: "heading", position: "relative" }}
        >
          <div
            sx={{
              height: projectElements ? "50%" : "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              sx={{
                lineHeight: 1,
                display: "flex",
                flexDirection: ["column", "row"],
                color: "white",
                fontSize: [5, 6, 6],
                //animation: `${popIn} 1s cubic-bezier(0, 0.9, 0.3, 1.2) forwards`,
                //opacity: 0,
                //animationDelay: ".4s",
                textAlign: ["center"],
              }}
            >
              <MarkDown
                sx={{ fontWeight: "light", p: { margin: 0, padding: 0 } }}
                data={headingLine1}
              ></MarkDown>
              <span sx={{ display: ["none", "block"] }}>&nbsp; </span>
              <MarkDown
                sx={{ fontWeight: "bold", p: { margin: 0, padding: 0 } }}
                data={headingLine2}
              ></MarkDown>
            </div>
            <div
              sx={{
                py: 4,
                fontWeight: "light",
                lineHeight: 1,
                color: "white",
                fontSize: [3, 4, 4],
                //animation: `${popIn} 1s cubic-bezier(0, 0.9, 0.3, 1.2) forwards`,
                //opacity: 0,
                //animationDelay: ".6s",
              }}
            >
              <MarkDown
                sx={{
                  p: {
                    margin: 0,
                    padding: 0,
                    lineHeight: "1.25",
                    textAlign: "center",
                  },
                }}
                data={tagLine}
              ></MarkDown>
            </div>
            <div
              sx={{
                pt: 2,
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                //animation: `${popIn} 1s cubic-bezier(0, 0.9, 0.3, 1.2) forwards`,
                //opacity: 0,
                //animationDelay: ".8s",
              }}
            >
              {primaryCta && (
                <Button sx={{}} variant="primary" {...primaryCta}></Button>
              )}
              {secondaryCta && (
                <Button variant="primary.outline" {...secondaryCta}></Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {projectElements && (
        <div
          sx={{
            width: "100%",
            maxWidth: "1400px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            sx={{
              color: "white",
              fontSize: 3,
              lineHeight: 3,
              fontWeight: "thin",
            }}
          >
            Latest Projects...
          </div>
          <div
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              //height: "50%",
              px: [2, 0, 0],
              //pt: [5],
              //position: "absolute",
              //bottom: 0,
            }}
          >
            {projectElements}
          </div>

          <Link sx={{ color: "primary", fontWeight: "light" }} to="/projects">
            See More Projects...
          </Link>
        </div>
      )}
    </div>
  );
};

export default Hero;
