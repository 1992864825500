/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Link } from "gatsby";
export type ButtonProps = {
  label: string;
  link: string;
  variant?: string;
  //sx?: object //we expect a sx prop but never use it below
  className?: string; //we expect a className prop but never define one when the component is called
};
export const Button: React.FC<ButtonProps> = ({
  label,
  link,
  variant,
  className,
}) => {
  return (
    <Link
      sx={{
        variant: `buttons.${variant}`,
      }}
      to={link}
      className={className}
    >
      <span>{label}</span>
    </Link>
  );
};

export default Button;
