import { ImageProps } from "../image/Interfaces";
import { FacebookSEOProps } from "./facebook-seo";
import { SeoProps } from "./seo";

export function getSeoMeta(page: {
  image?: ImageProps;
  name?: string;
  technology?: string[];
  services?: string[];
  cms?: string;
  design?: string;
  description: any;
  content?: any;
  url?: string;
  seoMetadata?: any;
  slug?: any;
  title?: any;
}): SeoProps | null {
  const seo = page.seoMetadata;
  if (!seo) return null;
  const seoProps: SeoProps = {
    canonical: page.slug,
    title: seo ? seo.title || page.title : page.title,
    description: seo ? seo.description || page.description : page.description,
    metaRobotsNoindex: seo
      ? seo.robotsNoIndex === null
        ? false
        : seo.robotsNoIndex
      : false,
    metaRobotsNofollow: seo
      ? seo.robotsNoFollow === null
        ? false
        : seo.robotsNoFollow
      : false,
  };

  let facebook: FacebookSEOProps | undefined;
  if (seo.facebookSeo) {
    facebook = {
      url: seoProps.canonical,
      name: "",
      type: "article",
      title: seo.facebookSeo.title || seoProps.title,
      desc: seo.facebookSeo.description || seoProps.description,
      image: seo.facebookSeo.image.localFile.publicURL,
      locale: "en",
    };
  }

  return { ...seoProps, facebook: facebook };
}
