/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { Fragment } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { ImageProps } from "./Interfaces";

// Render inline SVG with fallback non-svg images
const Image: React.FC<ImageProps> = ({
  svg,
  gatsbyImageData,
  loading,
  file,
  alt,
  width,
  height,
  styles,
  className,
}) => {
  width = width ? width : "100%";
  height = height ? height : "100%";

  //if there isn't an image, for some reason then get out of dodge.
  if (!file) return <Fragment />;

  if (file.contentType === "image/svg+xml") {
    if (svg && svg.content) {
      // Inlined SVGs
      //const svgTag = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">`;
      //const svgTag = `<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 ${width} ${height}">`;
      const content = (svg.content as string).replace(
        "<svg ",
        `<svg fill="currentColor" width="${width}" height="${height}"`
      );

      return (
        <div
          sx={{ ...styles }}
          className={className}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      );
    }

    // SVGs that can/should not be inlined
    return <img src={file.url} alt={alt} />;
  }

  // Non SVG images
  return (
    <GatsbyImage
      loading={loading || "lazy"}
      alt={alt}
      image={gatsbyImageData}
      className={className}
    />
  );
};

export default Image;

/* <GatsbyImage
      fixed={fixed}
      fluid={fluid}
      alt={alt}
      className={className}
      imgStyle={imageStyle}
   />*/
