/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { Fragment } from "react";
import { Markdown } from "./markdown.interface";

//#region  global helper functions
function isMarkdown(data: string | Markdown): data is Markdown {
  if (!data) return false;
  return (data as Markdown).childMarkdownRemark !== undefined;
}

export function GetHTMLFromMarkdown(data: Markdown): string {
  return data.childMarkdownRemark.html;
}
export function GetWordCountFromMarkdown(data: Markdown): number {
  return data.childMarkdownRemark.wordCount?.words || 0;
}

export function GetWordReadTimeFromMarkdown(data: Markdown): number {
  return data.childMarkdownRemark.timeToRead || 0;
}

//#endregion

export interface MarkdownProps {
  data: string | Markdown;
  variant?: string;

  sx?: any; //we expect a sx prop but never use it below
  className?: string; //we expect a className prop but never define one when the component is called
}
const MarkDown: React.FC<MarkdownProps> = ({ data, className }) => {
  let html = "";

  if (!data) return <Fragment />;

  if (isMarkdown(data)) {
    html = GetHTMLFromMarkdown(data);
  } else {
    html = data;
  }

  return (
    <div
      className={`whitespace-pre-wrap ${className}`}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};

export const StyledMarkDown: React.FC<MarkdownProps> = ({
  data,
  className,
  variant,
}) => {
  let html = "";
  if (isMarkdown(data)) {
    html = GetHTMLFromMarkdown(data);
  } else {
    html = data;
  }

  return (
    <div
      className={`whitespace-pre-wrap ${className}`}
      sx={{
        variant: variant ? variant : "styles",
        overflowWrap: "break-word ",
      }}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};
export default MarkDown;
