import { graphql, useStaticQuery } from "gatsby";
import React from "react";
//import { useStaticQuery, graphql } from "gatsby";
//import { useLocation } from "@reach/router";
import { Helmet } from "react-helmet";
import FacebookSEO, { FacebookSEOProps } from "./facebook-seo";

export type SeoProps = {
  canonical: string;
  title: string;
  description: string;
  metaRobotsNoindex: boolean;
  metaRobotsNofollow: boolean;
  facebook?: FacebookSEOProps;
};

//import FacebookSEO from "./facebook-seo";
//import TwitterSEO from "./twitter-seo";

const SEO: React.FC<SeoProps> = ({
  canonical,
  title,
  description,
  metaRobotsNoindex,
  metaRobotsNofollow,
  facebook,
}) => {
  const { contentfulSiteSettings: settings } = useStaticQuery(query);
  //const authorSeo = (author as User).seo;

  const robotsIndex = metaRobotsNoindex ? "noindex" : "index";
  const robotsFollow = metaRobotsNofollow ? "nofollow" : "follow";
  const url = `${settings.baseUrl}/${canonical === "home" ? "" : canonical}`;
  return (
    <React.Fragment>
      <Helmet>
        <html lang={settings.language} />
        <meta name="docsearch:version" content="2.0" />
        <meta
          name="robots"
          content={`max-snippet:-1, max-image-preview:large, max-video-preview:-1, ${robotsIndex}, ${robotsFollow}`}
        />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
        />

        {/* General tags */}
        <title>{`${settings.title} - ${title}`}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={url} />
      </Helmet>
      <FacebookSEO
        locale={settings.language}
        desc={facebook?.desc}
        image={facebook?.image ? `${settings.baseUrl}${facebook?.image}` : ""}
        title={facebook?.title}
        type={facebook?.type}
        url={url}
        name={settings.name}
      />
    </React.Fragment>
  );
};

export default SEO;
const query = graphql`
  query {
    contentfulSiteSettings {
      baseUrl
      language
      title
    }
  }
`;

/*
<FacebookSEO
        locale={wp.generalSettings.language}
        desc={seo.opengraphDescription}
        image={seo.opengraphImage ? seo.opengraphImage.sourceUrl : ""}
        title={seo.opengraphTitle}
        type={type}
        url={`https://www.planfocus.com.au/${seo.opengraphUrl}`}
        name={seo.opengraphSiteName}
      />
      <TwitterSEO
        title={seo.twitterTitle}
        image={seo.twitterImage}
        desc={seo.twitterImage}
        username={authorSeo?.twitter || ""}
        type={"summary_large_image"}
      />
*/
