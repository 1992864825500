/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import Image from "../utils/image/image";
import { ImageProps } from "../utils/image/Interfaces";
import { Link } from "gatsby";

export type ProjectCardProps = {
  image: ImageProps;
  name: string;
  technology: string[];
  cms: string;
  design: string;
  slug: string;
  content: any;
  services: string[];
};
export const ProjectCard: React.FC<ProjectCardProps> = ({
  image,
  slug,
  name,
}) => {
  //const [showDetail, setShowDetail] = useState(false);

  return (
    <div
      sx={{
        variant: "cards.primaryx",
        p: 0,
        position: "relative",
        cursor: "pointer",
      }}
    >
      <Link to={`/projects/${slug}`}>
        <Image
          sx={{
            height: "100%",
            borderRadius: "4px",
            maxWidth: "600px",
          }}
          {...image}
          alt={name}
          loading="lazy"
        />
      </Link>
    </div>
  );
};

export default ProjectCard;
